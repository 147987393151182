<template>
  <v-container fluid>
    <BasePageTitle>
      <div>
        DUT Configuration: Fixture Design({{ fdId }}) {{ partNumber }} - DUTConfig({{ $route.params.did }}) {{ dutConfigs.name }}
      </div>
    </BasePageTitle>
    <v-toolbar v-if="stateDescription" dense flat>
      <div>
        Fixture Design State:
        {{ fixtureStateName }}
        - {{ stateDescription }}
      </div>
    </v-toolbar>
    <v-row>
      <v-col cols="12" md="9">
        <BaseNavigationButton
          :tooltipText="
            isAdmin ? 'Back to Project Index' : 'Back to Quote Index'
          "
          :nextRoute="isAdmin ? 'projects' : 'quotes'"
          navIcon="mdi-chevron-left"
          color="warning"
          route
          :next="false"
          :left="true"
          :right="false"
        />
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              top
              left
              color="secondary"
              v-on="on"
              :disabled="!!isEditing || isEmptyData || isInvalidRecepProbe"
              class="ma-2"
              @click="
                gotoConfigure({
                  route: 'render',
                  projectPk: $route.params.id,
                  newtab: false,
                })
              "
              @contextmenu="
                activateMenu({
                  nextRoute: {
                    route: 'render',
                    projectPk: $route.params.id,
                    newtab: true,
                  },
                  nextAction: 'gotoConfigure',
                  event: $event,
                })
              "
              ><v-icon>mdi-rotate-3d</v-icon>
            </v-btn>
          </template>
          <span>{{
            !!isEditing || isEmptyData
              ? "Please add TestPoints / GuidePins / PressurePins"
              : "Go to 3D Render"
          }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              top
              left
              color="secondary"
              v-on="on"
              :disabled="!!isEditing || isEmptyData || isInvalidRecepProbe"
              v-if="hasRender"
              class="ma-2"
              @click="
                gotoConfigure({
                  route: 'fulfillment',
                  projectPk: $route.params.id,
                  newtab: false,
                })
              "
              @contextmenu="
                activateMenu({
                  nextRoute: {
                    route: 'fulfillment',
                    projectPk: $route.params.id,
                    newtab: true,
                  },
                  nextAction: 'gotoConfigure',
                  event: $event,
                })
              "
              ><v-icon>mdi-auto-fix</v-icon>
            </v-btn>
          </template>
          <span>{{
            !!isEditing || isEmptyData
              ? "Please add TestPoints / GuidePins / PressurePins"
              : "Go to Fixture Fulfillment"
          }}</span>
        </v-tooltip>
        <BaseNavigationButton
          tooltipText="Open Quote Details Modal"
          navIcon="mdi-archive-eye"
          color="secondary"
          :route="false"
          :left="true"
          :right="false"
          @action="quoteDetailsView(quoteId)"
          :disabled="!quoteId || quoteId.length == 0"
        />
        <BaseNavigationButton
          :tooltipText="
            !!isEditing || isEmptyData
              ? 'Please add TestPoints / GuidePins / PressurePins'
              : 'Download to CSV'
          "
          navIcon="mdi-database-arrow-down"
          color="secondary"
          :route="false"
          :left="true"
          :right="false"
          @action="csvHandler(el)"
          :disabled="!!isEditing || isEmptyData"
        />
        <BaseNavigationButton
          tooltipText="Upload new DUT Report"
          navIcon="mdi-upload"
          color="warning"
          :route="false"
          :left="true"
          :right="false"
          @action="openParserModal"
          :disabled="!!isEditing"
        />
      </v-col>
      <v-col cols="12" md="3" class="d-md-flex justify-end">
        <v-btn
          v-if="isAdmin"
          x-large
          color="warning"
          class="mr-5"
          @click="gotoSvgDrawing"
          >Go to Assembly Drawing
          <v-icon small class="ml-1">
            mdi-open-in-new
          </v-icon></v-btn
        >
        <v-btn
          v-if="isAdmin"
          x-large
          :disabled="!bomUrl"
          color="warning"
          target="_blank"
          :href="bomUrl"
          data-test="bom-button"
          >Go to Full BOM View
          <v-icon small class="ml-1">
            mdi-open-in-new
          </v-icon></v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-checkbox
          v-show="isAdmin"
          v-model="override"
          label="Override Validation Controls"
          color="warning"
          dense
          hide-details
          class="ma-2"
          :class="isInitialState || isAdmin ? 'admin-label' : ''"
          :disabled="!isInitialState && !isAdmin"
        ></v-checkbox>
        <v-row align="center" class="ma-2">
          <v-checkbox
            v-show="isAdmin"
            v-model="showerror"
            label="Show Validation Errors"
            color="warning"
            hide-details
            dense
            :class="isInitialState || isAdmin ? 'admin-label' : ''"
            :disabled="!isInitialState && !isAdmin"
          ></v-checkbox
          ><span v-show="showerror" class="mt-2 ml-3 font-italic"
            >(Highlighting invalid configuration values in
            <span class="error--text">RED</span>. Please adjust these before
            saving.)</span
          >
        </v-row>
        <v-row align="center" class="mt-2 mx-1">
          <v-switch
            v-show="isAdmin"
            v-model="bulkEditMode"
            :label="bulkEditMode ? 'Bulk Edit Enabled' : 'Bulk Edit Disabled'"
            class="mt-2 mx-2"
            :class="isInitialState || isAdmin ? 'user-label' : ''"
            color="primary"
            @change="initSelection"
            :disabled="!isInitialState && !isAdmin"
          ></v-switch>
          <v-btn
            v-if="bulkEditMode"
            color="secondary"
            class="mx-5"
            :disabled="this.selectedRowCount == 0"
            @click="deleteSelectedHandler"
            >Delete Selected Rows
            {{
              this.selectedRowCount > 0 ? `(${this.selectedRowCount} rows)` : ""
            }}</v-btn
          >
        </v-row>
        <v-row align="center" class="my-1 mx-1">
          <v-switch
            v-show="isAdmin"
            v-model="enabledViewMils"
            label="Show values in mils"
            class="mt-2 mx-2"
            :class="isInitialState || isAdmin ? 'user-label' : ''"
            color="primary"
            :disabled="!isInitialState && !isAdmin"
          ></v-switch>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <ClearanceGuideline />
      </v-col>
    </v-row>
    <v-sheet outlined>
      <v-container class="pa-5" fluid>
        <v-skeleton-loader v-if="isLoading" type="table"></v-skeleton-loader>
        <v-stepper v-else v-model="el" class="elevation-0">
          <v-stepper-header class="body-2 elevation-1">
            <v-stepper-step editable step="1" @click="setScale">
              Test Point Configuration
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step editable step="2" @click="setScale">
              Guide Pin Configuration
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step editable step="3" @click="setScale">
              Pressure Pin Configuration
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items class="no-transition">
            <v-stepper-content step="1">
              <ProbeConfigPage
                :override="override"
                :showerror="showerror"
                :bulkEditMode="bulkEditMode"
                :enabledViewMils="enabledViewMils"
                :isGerberExists="isGerberExists"
                :isInitialState="isInitialState"
                :isAdmin="isAdmin"
                :gerberValid="gerberValid"
                :result="result"
                :config2d="config2d"
                ref="tpChildComponent"
                @updateFilter="onUpdateFilter"
                @updateRender="setScale"
              />
            </v-stepper-content>
            <v-stepper-content step="2">
              <GuidePinPage
                :override="override"
                :showerror="showerror"
                :bulkEditMode="bulkEditMode"
                :enabledViewMils="enabledViewMils"
                :isInitialState="isInitialState"
                :isAdmin="isAdmin"
                :isGerberExists="isGerberExists"
                :gerberValid="gerberValid"
                ref="gpChildComponent"
                @updateRender="setScale"
              />
            </v-stepper-content>
            <v-stepper-content step="3">
              <PressurePinPage
                :override="override"
                :showerror="showerror"
                :bulkEditMode="bulkEditMode"
                :enabledViewMils="enabledViewMils"
                :isGerberExists="isGerberExists"
                :isInitialState="isInitialState"
                :isAdmin="isAdmin"
                :gerberValid="gerberValid"
                :result="result"
                :config2d="config2d"
                :testPoints="filteredTestPoints"
                ref="ppChildComponent"
                @nextStep="emitHandler"
                @updateRender="setScale"
              />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-container>
    </v-sheet>
    <v-skeleton-loader
      v-if="isLoading"
      type="image, actions"
      class="mt-5"
    ></v-skeleton-loader>
    <div v-else class="pa-10">
      <div v-if="isGerberExists">
        <div v-if="gerberValid">
          <GerberLayers
            key="2"
            v-if="zipGerberFiles.length > 0 && !isLoading"
            ref="gerber_layers_fd"
            :topCopperContainer="topCopperContainer"
            :topSoldermaskContainer="topSoldermaskContainer"
            :topSilkscreenContainer="topSilkscreenContainer"
            :bottomCopperContainer="bottomCopperContainer"
            :bottomSoldermaskContainer="bottomSoldermaskContainer"
            :bottomSilkscreenContainer="bottomSilkscreenContainer"
            :allMechanicalContainer="allMechanicalContainer"
            :allDrillContainer="allDrillContainer"
            :testPoints="testpointPlotter"
          />
        </div>
        <v-card v-else>
          <v-card-title>
            <h4 class="mx-auto my-16 text--secondary">
              FixturFab could not render your gerbers. We recommend uploading
              .GTL, .GTS, .GTO, .GTP, .GBL, .GBS, .GBO, .GBP, and .DRL gerber
              files.
            </h4>
          </v-card-title>
        </v-card>
      </div>
      <div v-else>
        <v-row class="ma-5">
          <v-col cols="12" class="d-flex justify-space-around">
            <UpdateGerberModal
              v-if="oldGerberFiles.length > 0 && !this.isGerberExists && !this.gerberZipFile"
              :isAdmin="isAdmin"
              :newUpload="false"
              :oldGerbers="oldGerberFiles"
              :gerberZipFile="gerberZipFile"
              :testPoints="testpointPlotter"
              @update2dRender="onUpdate2dRender($event)"
            />
            <UpdateGerberModal
              :isAdmin="isAdmin"
              :newUpload="true"
              :gerbers="gerbers"
              :gerberZipFile="gerberZipFile"
              :testPoints="testpointPlotter"
              @update2dRender="onUpdate2dRender($event)"
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <BaseBottomDialog
      :dialog="dialog"
      :message="confirmMessage"
      :action="confirmAction"
      @navToNext="navHandler"
      @donothing="stopDialog"
    />
    <FileParserModal
      v-if="parserModal"
      ref="dut_parser"
      :calledFromConfig="true"
      :dialog="parserModal"
      :parsedJsonBlob="parsedJsonBlob"
      :parsedTestPoints="parsedTestPoints"
      :parsedGuidePins="parsedGuidePins"
      :parsedPressurePins="parsedPressurePins"
      :parsedHoles="parsedHoles"
      :parsedDutData="parsedDutData"
      :parsedMissingHeaders="parsedMissingHeaders"
      :testpointLayers="testpointLayers"
      :testpointTypes="testpointTypes"
      :pcbStackup="
        dutConfigs.pcb_stackup ? JSON.parse(dutConfigs.pcb_stackup) : blankSvg
      "
      :isAdmin="isAdmin"
      savedDut=""
      :dutUnits="dutUnits"
      :dutDetails="{}"
      :dutReport="dutFieldDescriptions.dut_report"
      :altiumReport="dutFieldDescriptions.altium_report"
      :experimentalFiles="dutFieldDescriptions.experimental_files"
      @parseTestPoint="parseTestPoint"
      @saveParsedTestPoints="saveParsedTestPoints"
      @initCachedParsedData="initCachedParsedData"
      @closeModal="closeParserModal"
    />
    <QuoteDetailsModal
      :dialog="quoteDetailsModal"
      :quote="quote"
      :dutDetails="dutDetails"
      :users="users"
      :companies="companies"
      :projectType="projectType"
      :projectTimeline="projectTimeline"
      :fixtureWiring="fixtureWiring"
      :isAdmin="isAdmin"
      calledFromConfigure
      @onDownloadFile="downloadFile"
      @closeQuoteDetailsModal="onCloseQuoteDetailsModal"
    />
    <BaseSubMenuPopup
      :showMenu="showMenu"
      :xCoord="xCoord"
      :yCoord="yCoord"
      :nextAction="nextAction"
      :buttonText="buttonText"
      @onClick="subMenuClickHandler"
      @onCopyUrl="subMenuCopyUrl"
    />
  </v-container>
</template>
<script>
import GerberLayer from "@/mixins/GerberLayer";
import SubMenuPopup from "@/mixins/SubMenuPopup";
import {
  csvExporter,
  getFileString,
  fileDownloader,
} from "@/helpers/basehelper.js";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ConfigurePage",
  mixins: [GerberLayer, SubMenuPopup],
  components: {
    ProbeConfigPage: () => import("./ProbeConfigPage.vue"),
    GuidePinPage: () => import("./GuidePinPage.vue"),
    PressurePinPage: () => import("./PressurePinPage.vue"),
    UpdateGerberModal: () => import("../components/UpdateGerberModal.vue"),
    ClearanceGuideline: () => import("../components/ClearanceGuideline.vue"),
    FileParserModal: () =>
      import("@/modules/quotes/components/FileParserModal.vue"),
    QuoteDetailsModal: () =>
      import("@/modules/quotes/components/QuoteDetailsModal.vue"),
    GerberLayers: () =>
      import("@/modules/dutconfigs/components/GerberLayers.vue"),
  },
  data() {
    return {
      el: 1,
      minEl: 1,
      maxEl: 3,
      dialog: false,
      confirmAction: "",
      confirmMessage: "",
      nextPath: null,
      authPath: "/auth",
      isLoading: false,
      override: false,
      showerror: false,
      bulkEditMode: false,
      enabledViewMils: false,
      previousViewMils: false,
      loading2d: false,
      config2d: {
        stackupScale: 1000,
        inchToMm: 25.4,
        isUnitMm: true,
        pressureTip: null,
        pcbHeight: null,
        pcbWidth: null,
        topSvgWidth: null,
        topLayerExist: null,
        bottomLayerExist: null,
        viewBox: [0, 0, 0, 0],
      },
      filteredTestPoints: this.testPoints,
      xMultiplier: 1,
      yMultiplier: 1,
      receptacleColor: ["black", "red", "blue", "green", "gray", "teal"],
      probeColor: ["white", "orange", "yellow", "aqua", "lime", "olive"],
      pcbRenderKey: 0,
      parserModal: false,
      quoteDetailsModal: false,
    };
  },
  computed: {
    ...mapGetters({
      testPoints: "testpoints/testPoints",
      guidePins: "guidepins/guidePins",
      pressurePins: "pressurepins/pressurePins",
      probes: "dutconfigs/probes",
      tipStyles: "projects/tipStyles",
      dutConfigs: "dutconfigs/dutConfigs",
      quoteDutDetails: "dutconfigs/dutDetails",
      gerbers: "dutconfigs/gerberFiles",
      fixtureDesign: "fixturedesign/fixtureDesign",
      fdState: "fixturedesign/fdState",
      miniVariant: "ui/miniVariant",
      selectedProject: "projects/selProject",
      result: "dutconfigs/pcbStackup",
      fdStateLongList: "ui/fdStateLongList",
      isAdmin: "auth/isAdmin",
      parsedJsonBlob: "quotes/parsedJsonBlob",
      parsedTestPoints: "quotes/parsedTestPoints",
      parsedGuidePins: "quotes/parsedGuidePins",
      parsedPressurePins: "quotes/parsedPressurePins",
      parsedHoles: "quotes/parsedHoles",
      parsedDutData: "quotes/parsedDutData",
      parsedMissingHeaders: "quotes/parsedMissingHeaders",
      dutFieldDescriptions: "quotes/dutFieldDescriptions",
      pcbStackup: "quotes/pcbStackup",
      blankSvg: "quotes/blankSvg",
      dutUnits: "quotes/dutUnits",
      testpointLayers: "dutconfigs/testpointLayers",
      testpointTypes: "dutconfigs/testpointTypes",
      projectType: "quotes/projectType",
      projectTimeline: "quotes/projectTimeline",
      fixtureWiring: "quotes/fixtureWiring",
      quote: "quotes/quote",
      dutDetails: "quotes/dutDetailsView",
      companies: "projects/companyList",
      users: "projects/usersList",
      zipGerberFiles: "dutconfigs/zipGerberFiles",
    }),
    userId() {
      return this.$store.state.auth.user.pk;
    },
    isEditing() {
      return (
        this.testPoints.find((element) => element.edited) ||
        this.guidePins.find((element) => element.edited) ||
        this.pressurePins.find((element) => element.edited)
      );
    },
    isEmptyData() {
      return (
        this.testPoints.length == 0 &&
        this.guidePins.length == 0 &&
        this.pressurePins.length == 0
      );
    },
    isInvalidRecepProbe() {
      return (
        this.testPoints.filter(
          (element) => !element.receptaclePk || !element.probePk
        ).length > 0
      );
    },
    isGerberExists() {
      return this.quoteDutDetails.gerber_layer_file.length > 0;
    },
    savedGerbers() {
      return this.quoteDutDetails.gerber_layer_file;
    },
    gerberValid() {
      return this.quoteDutDetails.gerber_layer_file.length > 0;
    },
    isInitialState() {
      return this.fixtureDesign.state === 1;
    },
    fdId() {
      return this.fixtureDesign.pk;
    },
    fixtureState() {
      return this.fixtureDesign.state;
    },
    fixtureStateName() {
      const result = this.fdState.find(
        (element) => element.pk === this.fixtureDesign.state
      );
      return result ? result.description : "";
    },
    stateDescription() {
      const result = this.fdStateLongList.find(
        (element) => element.pk === this.fixtureDesign.state
      );
      return result ? result.description : null;
    },
    partNumber() {
      return this.fixtureDesign.part ? this.fixtureDesign.part.number : "";
    },
    activeDFP() {
      const record = this.fixtureDesign.design_file_packages.find(
        (element) => element.active
      );
      return record ? record : null;
    },
    hasRender() {
      const record = this.fixtureDesign.design_file_packages.find(
        (element) => element.active
      );
      if (record) {
        return record.render_file_generation;
      } else {
        return null;
      }
    },
    bomUrl() {
      return Object.values(this.selectedProject.fixture_design_details)[0]
        ?.bom_url;
    },
    selectedRowCount() {
      if (this.el == 1) {
        return this.$refs.tpChildComponent.selection.length;
      } else if (this.el == 2) {
        return this.$refs.gpChildComponent.selection.length;
      } else {
        return this.$refs.ppChildComponent.selection.length;
      }
    },
    quoteId() {
      return this.fixtureDesign.quote;
    },
    testpointPlotter() {
      return this.testPoints
        ? this.testPoints.map((element) => {
            const probe = this.probes.find(
              (probe) => probe.pk == element.probePk
            );
            let probeDesc = null;
            if (probe) {
              probeDesc = this.tipStyles.find(
                (tip) => tip.pk == probe.tip_style
              );
            }
            return {
              pk: element.pk,
              ref: element.ref,
              net: element.net,
              x: +element.x,
              y: +element.y,
              receptaclePk: element.receptaclePk,
              receptacle: element.receptacle,
              probePk: element.probePk,
              probe: element.probe,
              diameter: probe ? probe.diameter : null,
              tip_style: probe ? probe.tip_style : null,
              tip_name: probeDesc ? probeDesc.name : null,
              clearance: element.min_spacing,
              layer: element.layer,
              checked: true,
            };
          })
        : [];
    },
    gerberZipFile() {
      return this.quoteDutDetails.gerber_zip;
    },
    oldGerberFiles() {
      return this.dutConfigs.individual_gerber_files;
    },
  },
  methods: {
    csvExporter,
    getFileString,
    fileDownloader,
    ...mapActions({
      fetchComponents: "dutconfigs/getDutConfigComponents",
      fetchFixtureDesign: "fixturedesign/getFixtureDesign",
      clearDataGrid: "testpoints/deleteAllTestPoints",
      clearDutConfigs: "dutconfigs/initDutConfigsState",
      patchDutConfig: "dutconfigs/updateDutConfigs",
      fetchDutConfigs: "dutconfigs/getDutConfigsById",
      fetchPCBStackup: "dutconfigs/getPCBStackup",
      deleteOldGerberFile: "dutconfigs/removeOldGerberFile",
      createGerberFile: "dutconfigs/saveGerberFile",
      saveTestPointsBlob: "dutconfigs/saveTestPointsBlob",
      fetchTestPointsData: "dutconfigs/getTestPointsData",
      getDutDetailsById: "dutconfigs/getDutDetailsById",
      fetchProject: "projects/fetchProject",
      fetchUsers: "projects/fetchUsers",
      fetchCompanies: "projects/fetchCompanies",
      initSelectedProject: "projects/clearProject",
      toggleLoading: "ui/loading",
      convertTestPoint: "quotes/parseTestPointFile",
      getDutUnits: "quotes/getDutDetailsOptions",
      saveDutDetails: "dutconfigs/saveDutDetail",
      fetchQuote: "quotes/getQuoteOnly",
      fetchDutDetails: "quotes/getDutDetailsView",
      fetchStatus: "quotes/getQuoteStatus",
      postGerberLayerFile: "quotes/postGerberLayerFile",
      removeGerberLayerFile: "quotes/removeGerberLayerFile",
    }),
    emitHandler(next) {
      if (
        (this.el === this.minEl && !next) ||
        (this.el === this.maxEl && next)
      ) {
        return;
      } else {
        next ? this.el++ : this.el--;
      }
    },
    csvHandler(tab) {
      const arrData =
        tab == 1
          ? this.testPoints.map((testpoint) => ({
              ...testpoint,
              probe: `"${testpoint.probe}"`,
              receptacle: `"${testpoint.receptacle}"`,
            }))
          : tab == 2
          ? this.guidePins.map((guidepin) => ({
              ...guidepin,
              guide_pin_assembly: `"${guidepin.guide_pin_assembly}"`,
            }))
          : this.pressurePins.map((pressurepin) => ({
              ...pressurepin,
              pressure_pin_assembly: `"${pressurepin.pressure_pin_assembly}"`,
            }));
      const fileName = (
        this.dutConfigs.name +
        "-" +
        (tab == 1 ? "test_point" : tab == 2 ? "guide_pin" : "pressure_pin") +
        "-fixturfab_configuration.csv"
      ).toLowerCase();
      if (arrData.length > 0) {
        this.csvExporter({ arrData, fileName });
      } else {
        this.$store.commit(
          "ui/SNACK_BAR",
          "Can not process request, no data to download."
        );
      }
    },
    stopDialog() {
      this.dialog = false;
    },
    navHandler() {
      this.clearDataGrid();
      this.stopDialog();
      if (this.nextPath != null) {
        this.$router.replace(this.nextPath);
      }
    },
    preventNav() {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = "";
    },
    setScale() {
      if (this.isGerberExists && this.gerberValid) {
        this.$refs.gerber_layers_fd.initRender();
      }
    },
    initPcbStackUp() {
      this.config2d.pressureTip =
        this.result.top.units == "mm"
          ? this.result.top.viewBox[2] / this.result.top.width
          : this.result.top.viewBox[2] /
            this.result.top.width /
            this.config2d.inchToMm;
      this.config2d.topSvgWidth = this.result.top.viewBox[2];
      this.config2d.isUnitMm = this.result.top.units == "mm" ? true : false;
      this.config2d.pcbHeight = this.result.top.height;
      this.config2d.pcbWidth = this.result.top.width;
      this.config2d.topLayerExist =
        this.result.top.defs.length > 2 ? true : false;
      this.config2d.bottomLayerExist =
        this.result.bottom.defs.length > 2 ? true : false;
      this.config2d.viewBox = this.result.top.viewBox;
      console.log(this.config2d);
    },
    initSelection() {
      if (!this.override) {
        this.$refs.tpChildComponent.initSelection();
        this.$refs.gpChildComponent.initSelection();
        this.$refs.ppChildComponent.initSelection();
      }
    },
    deleteSelectedHandler() {
      if (this.el == 1) {
        this.$refs.tpChildComponent.deleteSelectedDialog();
      } else if (this.el == 2) {
        this.$refs.gpChildComponent.deleteSelectedDialog();
      } else {
        this.$refs.ppChildComponent.deleteSelectedDialog();
      }
    },
    onUpdateFilter(payload) {
      this.filteredTestPoints = payload;
    },
    onUpdateVB(payload) {
      if (payload.type == "x") {
        this.xMultiplier = payload.value;
      } else {
        this.yMultiplier = payload.value;
      }
    },
    async onUpdate2dRender({
      actionPayload,
      zipped_file,
      withExistingGerbers,
      isBackwardCompatibility,
    }) {
      const gerberLayers = actionPayload.map((element) => ({
        file: element.filename,
        gerber: element.gerber,
        layer: element.type,
        side: element.side,
        svg: element.svg,
      }));
      this.isLoading = true;
      try {
        this.toggleLoading("One moment please, saving Gerber files");

        if (withExistingGerbers) {
          await this.removeGerberLayerFile({
            dutDetailsId: this.quoteDutDetails.id
          });
        }

        for (let i = 0; i < gerberLayers.length; i++) {
          let gerberPayload = new FormData();
          gerberPayload.append("layer", gerberLayers[i].layer);
          gerberPayload.append("side", gerberLayers[i].side);
          gerberPayload.append("svg_data", gerberLayers[i].svg);
          gerberPayload.append("dut_details", this.quoteDutDetails.id);
          gerberPayload.append(
            "file",
            new Blob([gerberLayers[i].gerber], {
              type: "application/vnd.gerber",
            }),
            gerberLayers[i].file
          );
          await this.postGerberLayerFile(gerberPayload);
        }

        if (!isBackwardCompatibility) {
          let detailData = new FormData();
          detailData.append("gerber_zip", zipped_file);
          const detailsData = {
            editMode: !!this.dutConfigs.dut_details,
            id: this.dutConfigs.dut_details,
            data: detailData,
          };
          await this.saveDutDetails(detailsData);
        } else {
          await this.getDutDetailsById(this.dutConfigs.dut_details);
        }

        await this.loadSavedGerbers();
        this.toggleLoading();
        this.isLoading = false;
      } catch (err) {
        this.toggleLoading();
        this.isLoading = false;
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async openParserModal() {
      this.parserModal = true;
    },
    closeParserModal() {
      this.parserModal = false;
    },
    async parseTestPoint(payload) {
      let parseData = new FormData();
      parseData.append("testpoint_file", payload);
      parseData.append("project_pk", this.selectedProject.pk);
      const actionPayload = {
        data: parseData,
        dut_id: this.dutConfigs.pk,
      };
      try {
        this.toggleLoading("Parsing");
        await this.getDutUnits();
        await this.convertTestPoint(actionPayload);
        this.$refs["dut_parser"] && this.$refs["dut_parser"].initConfig2d();
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveParsedTestPoints({
      testpoint_file,
      dut_settings,
      parsed_json_blob,
    }) {
      const { test_points, guide_pins, pressure_pins } = parsed_json_blob;
      try {
        let detailData = new FormData();
        detailData.append("pcb_thickness", dut_settings.dut_height);
        detailData.append("pcb_width", dut_settings.dut_width);
        detailData.append("pcb_height", dut_settings.dut_length);
        detailData.append("units", dut_settings.units);
        detailData.append("x_origin", dut_settings.x_origin);
        detailData.append("y_origin", dut_settings.y_origin);
        detailData.append("dut_file_data", JSON.stringify(parsed_json_blob));
        if (testpoint_file) {
          detailData.append("test_point_file", testpoint_file);
        }
        if (!this.dutConfigs.dut_details) {
          detailData.append("user", this.dutConfigs.user);
          detailData.append("dut_name", this.dutConfigs.name);
        }
        const detailsData = {
          editMode: !!this.dutConfigs.dut_details,
          id: this.dutConfigs.dut_details,
          data: detailData,
        };
        this.toggleLoading("Saving DUT Report");
        const response = await this.saveDutDetails(detailsData);

        let configData = new FormData();
        configData.append("pcb_thickness", dut_settings.dut_height);
        configData.append("pcb_x", dut_settings.dut_width);
        configData.append("pcb_y", dut_settings.dut_length);
        if (!this.dutConfigs.dut_details) {
          configData.append("dut_details", response.id);
        }
        const configsData = {
          pk: this.dutConfigs.pk,
          data: configData,
        };
        await this.patchDutConfig(configsData);

        if (this.dutConfigs)
          this.toggleLoading(
            "Saving Test Points, Guide Pins and Pressure Pins"
          );
        await Promise.all([
          this.saveAllTestPoints({ testpoints: test_points }),
          this.saveAllGuidePins({ guidepins: guide_pins }),
          this.saveAllPressurePins({ pressurepins: pressure_pins }),
        ]);
        this.toggleLoading("Updating table view");
        await this.fetchTestPointsData({
          params: {
            params: { project_id: this.dutConfigs.project },
          },
          dutParams: {
            params: { dc_pk: this.dutConfigs.pk },
          },
          fdId: this.fdId,
        }),
          this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveAllTestPoints({ testpoints }) {
      try {
        const tpData = {
          test_points: testpoints,
        };
        await this.saveTestPointsBlob({
          project_id: this.dutConfigs.project,
          dut_id: this.dutConfigs.pk,
          data: tpData,
          data_type: "test-points",
        });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveAllGuidePins({ guidepins }) {
      try {
        const gpData = {
          guide_pins: guidepins,
        };
        await this.saveTestPointsBlob({
          project_id: this.dutConfigs.project,
          dut_id: this.dutConfigs.pk,
          data: gpData,
          data_type: "guide-pins",
        });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveAllPressurePins({ pressurepins }) {
      try {
        const ppData = {
          pressure_pins: pressurepins,
        };
        await this.saveTestPointsBlob({
          project_id: this.dutConfigs.project,
          dut_id: this.dutConfigs.pk,
          data: ppData,
          data_type: "pressure-pins",
        });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    initCachedParsedData() {
      this.$store.commit("quotes/INIT_PARSED_FILES");
    },
    gotoSvgDrawing() {
      let routeData = this.$router.resolve({
        name: "svg-drawing",
        params: {
          id: this.$route.params.id,
          fdId: this.fdId,
          dutId: this.$route.params.did,
        },
      });
      window.open(routeData.href, "_blank");
    },
    async quoteDetailsView(quoteId) {
      try {
        this.toggleLoading("Loading Quote Data");
        Object.keys(this.quote).length == 0 && (await this.fetchQuote(quoteId));
        if (this.dutDetails.length == 0) {
          await Promise.all([
            this.fetchDutDetails({ params: { quote_id: quoteId } }),
            this.fetchStatus(),
          ]);
        }
        this.users.length == 0 && (await this.fetchUsers());
        this.companies.length == 0 && (await this.fetchCompanies());
        this.quoteDetailsModal = true;
        this.toggleLoading();
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    onCloseQuoteDetailsModal() {
      this.quoteDetailsModal = false;
    },
    async downloadFile({ url, filename }) {
      const actionPayload = {
        url,
        file: filename,
      };
      this.toggleLoading("Downloading, one moment please");
      await this.fileDownloader(actionPayload);
      this.toggleLoading();
    },
    gotoConfigure({ route, projectPk, newtab }) {
      if (newtab) {
        let routeData = this.$router.resolve({
          name: route,
          params: { id: projectPk },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push({
          name: route,
          params: { id: projectPk },
        });
      }
    },
    async gotoConfigureCopyUrl({ route, projectPk }) {
      const routeFull = new URL(
        this.$router.resolve({
          name: route,
          params: { id: projectPk },
        }).href,
        window.location.origin
      ).href;
      console.log(routeFull);
      await navigator.clipboard.writeText(routeFull);
    },
    initLayers() {
      this.$refs["gerber_layers_fd"] &&
        this.$refs["gerber_layers_fd"].initializeViewBox();
    },
  },
  async mounted() {
    this.filteredTestPoints = this.testPoints;
    await this.loadSavedGerbers();
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing && to.path != this.authPath) {
      this.confirmAction = "navToNext";
      this.confirmMessage =
        "Are you sure to navigate away from current page? (Changes on DUT configuration will be discarded)";
      this.nextPath = to.path;
      this.dialog = true;
    } else {
      next();
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
  },
  beforeDestroy() {
    if (this.miniVariant) this.$store.commit("ui/MINI_VARIANT");
    window.removeEventListener("beforeunload", this.preventNav);
    this.clearDataGrid();
    this.clearDutConfigs();
    this.initSelectedProject();
  },
};
</script>
<style scoped>
.admin-label /deep/ label {
  color: #950952 !important;
}
.user-label /deep/ label {
  color: #002f36 !important;
}
.mdi-paperclip::before {
  content: "\F39A";
  color: #950952;
}
</style>
